import React from "react";
import { graphql, Link } from "gatsby";
import { MainLayout } from "../layouts";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import ogImg from "../images/metadata.png";

import {
  PodcastArt,
  PodcastContent,
  PodcastPlayer,
  ListenOn,
} from "../components";
import { Headline } from "../components/atoms";
import stripHTML from "../utils/stripHTML";

const Podcast = ({ data }) => {
  // content from markdown
  const { html } = data.markdownRemark;
  const { featuredImg } = data.markdownRemark.frontmatter;
  const image = getImage(featuredImg);

  // content from buzzsprout API
  const { title, description, hide, audio_url } =
    data.podcastEpisodeControlRemoto;
  const stripDescription = description ? stripHTML(description) : null;

  // content from site metadata
  const { siteUrl } = data.site.siteMetadata;

  return (
    <MainLayout>
      <Helmet>
        <title> {title} | The Yes-code Podcast | yescode.org</title>
        <meta
          name="description"
          content={
            stripDescription ||
            "Designed for developers with speed and flexibility in mind, Yes-code tools deliver batteries-included solutions to domain-layer problems so we can focus on the code that matters in our applications."
          }
        />
        <meta name="keywords" content="code, developers, manifesto" />
        <meta property="og:url" content="https://www.yescode.org/podcast" />
        <meta property="og:title" content={`${title} | The Yes-code Podcast`} />
        <meta
          property="og:description"
          content={
            stripDescription ||
            "Designed for developers with speed and flexibility in mind, Yes-code tools deliver batteries-included solutions to domain-layer problems so we can focus on the code that matters in our applications."
          }
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${siteUrl}${ogImg}`} />
        <meta property="og:image:width" content="1080" />
        <meta property="og:image:height" content="608" />
        <meta property="og:image:alt" content="Yes-code " />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${title} | The Yes-code Podcast`}
        />
        <meta
          name="twitter:description"
          content={
            stripDescription ||
            "Designed for developers with speed and flexibility in mind, Yes-code tools deliver batteries-included solutions to domain-layer problems so we can focus on the code that matters in our applications."
          }
        />
        <meta name="twitter:image" content={`${siteUrl}${ogImg}`} />
        <link rel="icon" href="/" />
        <html lang="en" />
      </Helmet>
      <Link to="/podcast">go back</Link>
      <Headline tag="h1">{title}</Headline>
      {hide ? null : <ListenOn />}
      {featuredImg ? (
        <PodcastArt>
          <GatsbyImage image={image} alt="podcast image" />
        </PodcastArt>
      ) : null}
      <Headline tag="h3">{stripDescription || null}</Headline>
      {hide ? null : <PodcastPlayer title={title} audio_url={audio_url} />}
      <PodcastContent>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </PodcastContent>
    </MainLayout>
  );
};

export default Podcast;

export const query = graphql`
  query podcastPages($slug: String, $buzzsproutSlug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        featuredImg {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }

    podcastEpisodeControlRemoto(slug: { eq: $buzzsproutSlug }) {
      title
      description
      id
      published_at(formatString: "M/D/Y")
      episode_number
      audio_url
      hide: private
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
